import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { ABOUT_US_PATH } from '@flock/shared-ui/src/routeConstants'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

export default () => {
  useEffect(() => {
    navigate(ABOUT_US_PATH)
  }, [])
  return null
}
